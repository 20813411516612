import {
  ClipboardPlugin,
  CordovaClipboardPlugin
} from 'packages/clipboard-plugin/types';
import { WebSdkModel } from 'packages/embedded-web-sdk';

export const createWebClipboardPlugin = (
  instanceWindow: Window
): ClipboardPlugin => {
  const { navigator } = instanceWindow;
  return {
    async copy(
      copyText: string,
      onSuccess?: (copyText: string) => void,
      onFailure?: (err: unknown) => void
    ) {
      try {
        await navigator.clipboard.writeText(copyText);
        if (onSuccess) onSuccess(copyText);
      } catch (error) {
        if (onFailure) onFailure(error);
      }
    },
    paste(onSuccess?: any, onFailure?: any) {
      navigator.clipboard.readText().then(onSuccess).catch(onFailure);
    },
    clear(onSuccess?: any, onFailure?: any) {
      return this.copy('', onSuccess, onFailure);
    },
    checkAvailability() {
      return !!navigator.clipboard && !!navigator.clipboard.writeText;
    }
  };
};

export const createWebSdkClipboardPlugin = (
  exec: WebSdkModel['exec']
): ClipboardPlugin => {
  const plugin = 'ClipboardPlugin';
  return {
    copy(copyText: string, onSuccess?: any, onFailure?: any) {
      exec({
        plugin,
        action: 'copy',
        params: copyText
      })
        .then(onSuccess)
        .catch(onFailure);
    },
    async paste(onSuccess?, onFailure?) {
      const result = await exec({
        plugin,
        action: 'paste'
      });
      if (result.error && onFailure) onFailure(result.error);
      else onSuccess && onSuccess(result);
      return result || '';
    },
    clear(onSuccess?, onFailure?) {
      exec({
        plugin,
        action: 'clear'
      })
        .then(onSuccess)
        .catch(onFailure);
    },
    checkAvailability() {
      //TODO: if ever needed for websdk need to research how to determine clipboard availability
      return true;
    }
  };
};

export const createCordovaClipboardPlugin = (
  clipboard?: CordovaClipboardPlugin
): ClipboardPlugin => {
  return {
    copy(copyText, onSuccess?, onFailure?) {
      const cordovaPluginMethod = clipboard?.copy;
      if (cordovaPluginMethod)
        cordovaPluginMethod(copyText, onSuccess, onFailure);
    },
    paste(onSuccess?, onFailure?) {
      const cordovaPluginMethod = clipboard?.paste;
      if (cordovaPluginMethod) cordovaPluginMethod(onSuccess, onFailure);
    },
    clear(onSuccess?, onFailure?) {
      const cordovaPluginMethod = clipboard?.clear;
      if (cordovaPluginMethod) cordovaPluginMethod(onSuccess, onFailure);
    },
    checkAvailability() {
      return !!(clipboard && clipboard.copy);
    }
  };
};
